<template>
  <fileReader
    v-model="file"
    :field="field"
    :fieldAttributes="fieldAttributes"
    :fieldAttrInput="fieldAttributes.fieldAttrInput"
    @load="registerUpload()"
    :templateContent="result"
  ></fileReader>
</template>
<script>
import fileReader from "@/commonComponents/fileReader.vue";
export default {
  props: ["fieldAttributes", "field","result"],
  data() {
    return { file: null };
  },
  components: { fileReader },
  methods: {
    registerUpload() {
      this.$emit("file-upload", this.field, this.file);
    },
  },
};
</script>